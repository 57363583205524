
import { defineComponent, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import { b64toBlob } from "@/utils/claim/b64toBlob";
import {
  viewFile,
  operationRecord,
  viewFileTrande,
  appealRecordTrande,
  appealRecord,
  checkSingleTrande,
  checkSingle,
  cpoSalesFeedbackInfo,
  tradeInFeedbackInfo,
} from "@/API/userCar";
import moment from "moment";
import downloadFile from "@/utils/rv/downloadFile";
import { PlusCircleOutlined } from "@ant-design/icons-vue";
import Comment from "@/views/UserCar/Components/Comment.vue";
export default defineComponent({
  emits: ["update:visible", "close-view-visible", "init-table"],
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    isViewData: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  components: {
    PlusCircleOutlined,
    Comment,
  },
  setup(props, { emit }) {
    const aUrl = ref<string>("");
    const commentModalVisible = ref(false);
    const failFeedback = ref("");
    const columns = [
      { title: "操作角色", dataIndex: "operationRole", align: "center" },
      {
        title: "操作动作",
        dataIndex: "operationAction",
        align: "center",
        width: 100,
      },
      { title: "操作时间", dataIndex: "updatedAt", align: "center" },
    ];
    const dataSource = ref([]);
    const viewList = ref<any>([]);
    // 审核结果
    const approvalResultArray = reactive([
      {
        code: "8",
        msg: "核查通过",
      },
      {
        code: "7",
        msg: "核查未通过",
      },
    ]);
    // 审核原因
    const optionsMsg = ref<{ lable: string; value: string }[]>([]);
    //申诉信息描述返回
    const appealMsg = ref<string>("");
    // 审核表格
    const checkForm = reactive<any>({
      checkResult: undefined,
      checkMsg: [],
    });

    const title = ref<string>("支持文档信息");
    const isTab = ref<number>(0);
    // 核查结果变更函数
    const changeResult = (val: string) => {
      checkForm.checkMsg.length = 0; //清空上一个选择结果
      if (val === "8") {
        optionsMsg.value = [{ lable: "审核通过", value: "审核通过" }];
      } else {
        optionsMsg.value = [];
      }
    };
    // 处理图片文件数组
    const handleImgArr = (arr: any) => {
      viewList.value = [];
      arr.forEach((ele: any) => {
        const temp = {
          imgUrl: "",
          downUrl: "",
          docType: true,
          desType: ele.desType,
        };
        if (ele.base64) {
          temp.imgUrl = URL.createObjectURL(
            b64toBlob(ele.base64, "application/pdf")
          );
          temp.docType = false;
        } else {
          temp.imgUrl = ele.downloadUrl;
          temp.docType = true;
        }
        temp.downUrl = ele.downloadUrl;
        viewList.value.push(temp);
        return viewList.value;
      });
      aUrl.value = viewList.value[0].downUrl;
    };
    // 初始化可查看页面 cpo+置换
    const init = async () => {
      if (props.isViewData.types == 0) {
        // cpo销售的
        viewFile({
          id: props.isViewData.id,
          type: props.isViewData.types,
        }).then((res: any) => {
          if (res.length) {
            handleImgArr(res);
            // viewList.value = res;
            // aUrl.value = viewList.value[0].downloadUrl;
          }
        });
      } else {
        // 置换的查看
        viewFileTrande({
          id: props.isViewData.id,
          // type: props.isViewData.types,
          type: 0, // 0代表查询所有文件
        }).then((res: any) => {
          if (res.length) {
            // viewList.value = res;
            // aUrl.value = viewList.value[0].downloadUrl;
            handleImgArr(res);
          }
        });
      }
      const feedback =
        props.isViewData.types == 0
          ? await cpoSalesFeedbackInfo(props.isViewData.id)
          : await tradeInFeedbackInfo(props.isViewData.id);
      if (feedback === "审核通过") {
        checkForm.checkResult = "8";
        optionsMsg.value = [{ lable: "审核通过", value: "审核通过" }];
        checkForm.checkMsg = ["审核通过"];
      } else if (feedback !== "") {
        checkForm.checkResult = "7";
        failFeedback.value = feedback;
      }
    };
    // 初始化操作记录信息 cpo+置换
    const initRecord = () => {
      // cpo销售+置换通用接口
      operationRecord({
        mainId: props.isViewData.id,
        type: props.isViewData.operationType,
      }).then((res) => {
        dataSource.value = res;
      });
    };
    // 下载全部图片
    const downLoadAllImg = () => {
      const params = {
        url: viewList.value[0].downloadUrl,
        method: "get",
        fileName: `${moment(new Date()).format("YYYYMMDDhhmmss")}${{
          title,
        }}.jpg`,
      };
      downloadFile(params);
    };

    // 审核通过
    const checkOk = () => {
      if (
        (checkForm.checkResult === "8" && checkForm.checkMsg.length) || // 选中审查通过
        (checkForm.checkResult === "7" && failFeedback.value !== "")
      ) {
        // 选中审查未通过
        const param = {
          id: props.isViewData.id,
          checkResult: checkForm.checkResult,
          msg:
            checkForm.checkResult === "8"
              ? checkForm.checkMsg.join(";")
              : failFeedback.value,
        };
        if (props.isViewData.types == 0) {
          // cpo销售的
          checkSingle(param).then(() => {
            message.success("操作成功");
            emit("init-table", {}); //审核完成刷新主页表格
            emit("update:visible", false); //审核完成关闭弹窗
          });
        } else {
          checkSingleTrande(param).then(() => {
            message.success("操作成功");
            emit("init-table", {}); //审核完成刷新主页表格
            emit("update:visible", false); //审核完成关闭弹窗
          });
        }
      } else {
        message.error("请选择核查结果与反馈信息！");
      }
    };
    // 取消审核
    const cancel = () => {
      checkForm.checkResult = undefined;
      checkForm.checkMsg = [];
      emit("update:visible", false); //取消审核关闭弹窗
    };
    // 申诉记录审核文档
    const docList = ref([]);
    const getRecordAppeal = () => {
      if (props.isViewData.types == 0) {
        //  cpo
        appealRecord({ id: props.isViewData.id }).then((res) => {
          console.log(res, "成功");
          const data = res.docList;
          for (let i = 0; i < data.length; i++) {
            if (data[i].docType === "PDF") {
              data[i].base64 = URL.createObjectURL(
                b64toBlob(data[i].base64, "application/pdf")
              );
            }
          }
          docList.value = data;
          appealMsg.value = res.appealMsg;
        });
      } else {
        //  置换
        appealRecordTrande({ id: props.isViewData.id }).then((res) => {
          console.log(res, "置换申诉记录");
          const data = res.docList;
          for (let i = 0; i < data.length; i++) {
            if (data[i].docType === "PDF") {
              data[i].base64 = URL.createObjectURL(
                b64toBlob(data[i].base64, "application/pdf")
              );
            }
          }
          docList.value = data;
          appealMsg.value = res.appealMsg;
        });
      }
    };

    // 切换页签
    const changeTab = (param: number) => {
      isTab.value = param;
      if (param == 1) {
        initRecord();
      } else if (param == 2) {
        getRecordAppeal();
      } else if (param == 0) {
        init();
      }
    };
    changeTab(0); //初始化

    const handleToAddComment = () => {
      commentModalVisible.value = true;
    };

    const handleChangeFeedback = (feedback: string) => {
      failFeedback.value = feedback;
    };

    return {
      changeResult,
      viewList,
      title,
      close,
      labelCol: { style: { width: "100px" } },
      wrapperCol: { span: 14 },
      approvalResultArray,
      optionsMsg,
      downLoadAllImg,
      checkForm,
      changeTab,
      isTab,
      getRecordAppeal,
      dataSource,
      columns,
      aUrl,
      docList,
      checkOk,
      cancel,
      appealMsg,
      commentModalVisible,
      handleToAddComment,
      failFeedback,
      handleChangeFeedback,
    };
  },
});
