
import {
  reasonList,
  reasonDetailList
} from '@/API/userCar'
import useModel from '@/hooks/useModel'
import { message } from 'ant-design-vue'
import {
  ref,
  defineComponent,
  computed,
} from 'vue'
import { debounce } from 'lodash-es'
import type { CpoFeedback } from '@/views/UserCar/types'

interface Option {
  value: number;
  label: string;
}

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["save-data", 'update:visible'],
  setup(props, ctx) {
    const visibleModel = useModel(props, 'visible');

    const tagCN = ref<undefined | {value: number, label: string}>()
    const descirption = ref<undefined | {value: number, label: string}>()
    const comment = ref<string>('')
    const comments = ref<Omit<CpoFeedback, 'id'>[]>([])
    const fetching = ref(false)

    //两个动态下拉框
    const dataTag = ref<Option[]>([])
    const dataDescription = ref<Option[]>([])

    const handleCancel = () => {
      comment.value = ''
      tagCN.value = undefined
      descirption.value = undefined
      dataTag.value = []
      dataDescription.value = []
      comments.value = []
      visibleModel.value = false
    }

    const handleSave = async () => {
      ctx.emit('save-data', comments.value.map(item => `${item.tagCn}-${item.description}-${item.comments}；`).join(''))
      handleCancel()
    }

    const noTag = computed(() => {
      return !tagCN.value
    });

    const getTag = async (tag: string) => {
      fetching.value = true;
      try {
        const res = await reasonList(tag)
        dataTag.value = [];
        if (res.length === 0) {
          message.info("No data found")
        } else {
          dataTag.value = res.map(item => {
            return {
              value: item.id,
              label: item.tagCn,
            }
          })
        }
      } finally {
        fetching.value = false;
      }
    };

    const handleSearchTag = debounce(getTag, 500)
    const handleChangeTag = async (selected: {value: number, label: string}) => {
      tagCN.value = selected
      descirption.value = undefined
      dataTag.value = []
      dataDescription.value = []
      if (selected) {
        const data = (await reasonDetailList(selected.value)).map(item => {
          return {
            value: item.id,
            label: item.description
          }
        })
        if (!data.length) {
          message.info("No data found")
        }
        dataDescription.value = data
      }
    }

    const handleResetComment = () => {
      comments.value = []
      tagCN.value = undefined
      descirption.value = undefined
      comment.value = ''
      dataTag.value = []
      dataDescription.value = []
    };

    const handleAddComment = () => {
      if (!tagCN.value) {
        message.error("Please select Tag_CN")
        return
      }
      if (!descirption.value) {
        message.error("Please select Descirption")
        return
      }
      if (!comment.value) {
        message.error("Please input Comments")
        return
      }

      comments.value.push({
        checkReasonId: tagCN.value.value,
        tagCn: tagCN.value.label,
        checkReasonDetailId: descirption.value.value,
        description: descirption.value.label,
        comments: comment.value
      })
    };

    const commentsStr = computed(() => {
      if (!comments.value.length) {
        return ''
      }
      return comments.value.map(item => `${item.tagCn}-${item.description}-${item.comments}`).join('\n')
    })

    return {
      visibleModel,
      comments,
      handleSave,
      handleCancel,
      tagCN,
      descirption,
      comment,
      handleResetComment,
      dataTag,
      handleSearchTag,
      handleChangeTag,
      dataDescription,
      handleAddComment,
      noTag,
      fetching,
      commentsStr
    }
  },
})
