
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { getRegionApi } from "@/API/checking/SICCOCheckingDetail";
import useDealerOverview from "@/hooks/rv/useDealerOverview";
import UploadFiles from "@/views/UserCar/Components/BatchUploadSubmit.vue";
import downloadFile from "@/utils/rv/downloadFile";
import { message, Modal } from "ant-design-vue";
import checkUpload from "@/views/UserCar/Components/checkUpload.vue";
import checkModel from "@/views/UserCar/Components/checkModel.vue";
import batchInputModel from "@/views/UserCar/Components/batchInputModel.vue";
import { s3Download } from "@/utils/s3Download";

import moment from "moment";
import {
  getOverViewTrande,
  getOverView,
  getpageList,
  getpageListTrande,
  batchSubmit,
  batchSubmitTrande,
} from "@/API/userCar";

const tableWidth = window.innerWidth;

export default defineComponent({
  components: { UploadFiles, checkUpload, checkModel, batchInputModel },
  props: {
    make: {
      type: String,
      default: "",
    },
    // makeId: {
    //   type: Number,
    //   required: true,
    //   default: 1,
    // },
    makeYear: {
      type: String,
      default: "",
    },
    makeMonth: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const headCheckState = ref("102");
    //region的数组
    const regionArray = ref<any[]>([]);
    const exportParams = ref<any>();
    const tableHeight = ref<number>(window.innerHeight - 470);
    const isSearch = ref<boolean>(false);
    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 10,
    });
    const size = ref<number>(0);
    const isSelect = ref<string>("0");
    const salesCard = ref<any>({
      submitDate: "NO-Data",
      totalSubmitCount: 0,
      unCheckedCount: 0,
      appealUnCheckedCount: 0,
      unReleasedCount: 0,
    });

    const { isOpenPanle, isOpen } = useDealerOverview();
    const tableData = reactive<any>([]);
    const tableDataTrande = reactive<any>([]);
    // 表格当前页参数信息
    const tableCurrentInfo = reactive({});

    // 上传核查结果关闭弹窗
    const isCheckUploadVisible = ref<boolean>(false);
    const checkUploadClose = () => {
      isCheckUploadVisible.value = false;
    };
    // 核查操作框
    const isCheckVisible = ref<boolean>(false);
    const checkList = ref<unknown>({});
    const checkClose = () => {
      isCheckVisible.value = false;
    };
    // 头部参数信息
    const headerParams = ref<any>({
      quarter: null,
      unSubmit: 102,
    });
    // 表头数组
    // 审核状态
    const approvalStatusArray = reactive([
      {
        code: 2,
        msg: "dealer submitted",
      },
      {
        code: 3,
        msg: "SICCO Init",
      },
      // {
      //     code: 9,
      //     msg: '已申诉待核查',
      // },
      {
        code: 4,
        msg: "SICCO checked",
      },
      {
        code: 6,
        msg: "SIPO released",
      },
      {
        code: 5,
        msg: "SIPO rejected",
      },
    ]);
    // 审核结果
    const approvalResultArray = reactive([
      {
        code: 0,
        msg: "unchecked",
      },
      {
        code: 1,
        msg: "approved",
      },
      {
        code: 2,
        msg: "rejected",
      },
    ]);
    // CPO销售页签表头
    const columnsCPO = reactive<any>([
      {
        title: "Regions",
        dataIndex: "region",
        align: "center",
        width: 200,
      },
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        align: "center",
        width: 100,
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        align: "center",
        width: 300,
      },
      {
        title: "Seller",
        dataIndex: "seller",
        align: "center",
        width: 100,
      },
      {
        title: "ID No./Legal Entity Code Of Seller",
        dataIndex: "sellerCode",
        align: "center",
        width: 250,
      },

      {
        title: "Certification Date",
        dataIndex: "certificateDate",
        align: "center",
        width: 200,
      },
      {
        title: "VIN No.",
        dataIndex: "vinNo",
        align: "center",
        width: 200,
      },
      {
        title: "Series",
        dataIndex: "series",
        align: "center",
        width: 170,
      },
      {
        title: "Model",
        dataIndex: "typeClass",
        align: "center",
        width: 100,
      },
      {
        title: "1st Registration Date",
        dataIndex: "firstRegistDate",
        align: "center",
        width: 190,
      },
      {
        title: "New Car Warranty Start Date",
        dataIndex: "startRepairDate",
        align: "center",
        width: 230,
      },
      {
        title: "Buyer",
        dataIndex: "buyerName",
        align: "center",
        width: 220,
      },
      {
        title: "Warranty Number",
        dataIndex: "dealerCode",
        align: "center",
        width: 220,
      },
      {
        title: "Certificate No.",
        dataIndex: "certificateCode",
        align: "center",
        width: 100,
      },
      {
        title: "Title Transfer Type",
        dataIndex: "transferType",
        align: "center",
        width: 190,
        slots: { customRender: "transferType" },
      },
      {
        title: "UC Invoice Date",
        dataIndex: "invoiceDate",
        align: "center",
        width: 190,
      },
      {
        title: "UC Title Transfer Date",
        dataIndex: "transferDate",
        align: "center",
        width: 190,
      },
      {
        title: "Submission Date",
        dataIndex: "submitDate",
        align: "center",
        width: 150,
      },
      {
        title: "Checking Status",
        dataIndex: "checkStatus",
        align: "center",
        width: 120,
        slots: { customRender: "checkStatus" },
      },
      {
        title: "Checking Result",
        dataIndex: "checkResult",
        align: "center",
        width: 120,
        slots: { customRender: "checkResult" },
      },
      {
        title: "Feedback Comments",
        dataIndex: "feedbackMsg",
        align: "center",
        width: 200,
      },
      {
        title: "Operation",
        dataIndex: "action",
        fixed: "right",
        slots: { customRender: "action" },
        align: "center",
        width: 200,
      },
    ]);
    // 置换页签的表头
    const columns = reactive<any>([
      {
        title: "Regions",
        dataIndex: "region",
        align: "center",
        width: 200,
      },
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        align: "center",
        width: 100,
      },
      {
        title: "Dealer Name",
        dataIndex: "dealerName",
        align: "center",
        width: 300,
      },
      {
        title: "Brand/Series",
        dataIndex: "series",
        align: "center",
        width: 170,
      },
      {
        title: "Model",
        dataIndex: "typeClass",
        align: "center",
        width: 100,
      },
      {
        title: "UC VIN",
        dataIndex: "usedCarVinNo",
        align: "center",
        width: 100,
      },
      {
        title: "1st Registration Date",
        dataIndex: "firstRegistDate",
        align: "center",
        width: 190,
      },
      {
        title: "UC Title Transfer Date",
        dataIndex: "transferDate",
        align: "center",
        width: 190,
      },
      {
        title: "VIN No.",
        dataIndex: "vehicleVinNo",
        align: "center",
        width: 200,
      },
      {
        title: "New Car Invoice Date",
        dataIndex: "newInvoiceDate",
        align: "center",
        width: 190,
      },
      {
        title: "Customer Name",
        dataIndex: "seller",
        align: "center",
        width: 190,
      },
      {
        title: "NC buyer Customer Name",
        dataIndex: "buyerName",
        align: "center",
        width: 190,
      },
      {
        title: "Submission Date",
        dataIndex: "submitDate",
        align: "center",
        width: 190,
      },
      {
        title: "Checking Status",
        dataIndex: "checkStatus",
        align: "center",
        width: 120,
        slots: { customRender: "checkStatus" },
      },
      {
        title: "Checking Result",
        dataIndex: "checkResult",
        align: "center",
        width: 120,
        slots: { customRender: "checkResult" },
      },
      {
        title: "Feedback Comments",
        dataIndex: "feedbackMsg",
        align: "center",
        width: 200,
      },
      {
        title: "Operation",
        dataIndex: "action",
        fixed: "right",
        slots: { customRender: "action" },
        align: "center",
        width: 200,
      },
    ]);

    // 提交日期起止时间
    const submitStartDate = ref<unknown>("");
    const submitEndDate = ref<unknown>("");
    const newInvoiceDateFrom = ref<unknown>("");
    const newInvoiceDateTo = ref<unknown>("");
    const dealerCode = ref<string>("");
    const dealerName = ref<string>("");
    const regionVal = ref<string | null>(null);
    const approlResult = ref(null);
    const approlStatus = ref(null);
    const carNo = ref(); //车牌号码
    const vinNo = ref(""); //车架号
    const vinNoArr = ref<string[]>([]); //车架号数组
    const newVinNo = ref();
    const newVinNoArr = ref<any>([]);
    const typeClass = ref(""); //车牌号码
    const isAppeal = ref();
    //过户时间
    const transformStartDate = ref<unknown>("");
    const transformEndDate = ref<unknown>("");
    const isInputVisible = ref<boolean>(false);
    const isInputVisibleNew = ref(false);
    // 批量查询vinNO
    const batchQueryEvent = (type: string) => {
      if (type === "new") {
        isInputVisibleNew.value = true;
      } else {
        isInputVisible.value = true;
      }
    };

    watch(vinNoArr, (values) => {
      vinNo.value = values.length ? values[0] : "";
    });

    watch(newVinNoArr, (values) => {
      newVinNo.value = values.length ? values[0] : "";
    });

    // 提交日期选择器事件
    const dateChangeSubmit = (params: any) => {
      submitStartDate.value = params[0];
      submitEndDate.value = params[1];
    };
    const dateChangeInvoiceNew = (params: any) => {
      newInvoiceDateFrom.value = params[0];
      newInvoiceDateTo.value = params[1];
    };
    // 过户日期筛选
    const dateChangeTranstorm = (params: any) => {
      transformStartDate.value = params[0];
      transformEndDate.value = params[1];
    };
    // certification日期筛选
    const certificationStartDate = ref<unknown>("");
    const certificationEndDate = ref<unknown>("");
    const dateChangeCertification = (params: any) => {
      certificationStartDate.value = params[0];
      certificationEndDate.value = params[1];
    };
    // new car日期筛选
    const newStartDate = ref<unknown>("");
    const newEndDate = ref<unknown>("");
    const dateChangeNew = (params: any) => {
      newStartDate.value = params[0];
      newEndDate.value = params[1];
    };
    const newInvoiceStartDate = ref<unknown>("");
    const newInvoiceEndDate = ref<unknown>("");
    const dateChangenewInvoice = (params: any) => {
      newInvoiceStartDate.value = params[0];
      newInvoiceEndDate.value = params[1];
    };

    const registrationStartDate = ref<unknown>("");
    const registrationEndDate = ref<unknown>("");
    const dateChangeRegistration = (params: any) => {
      registrationStartDate.value = params[0];
      registrationEndDate.value = params[1];
    };
    const invoiceStartDate = ref<unknown>("");
    const invoiceEndDate = ref<unknown>("");
    const dateChangeInvoice = (params: any) => {
      invoiceStartDate.value = params[0];
      invoiceEndDate.value = params[1];
    };
    // 处理表格高度
    const refreshTableHeight = () => {
      if (isOpen.value) {
        if (isSelect.value == "0") {
          tableHeight.value = window.innerHeight - 595;
        } else {
          tableHeight.value = window.innerHeight - 515;
        }
      } else {
        if (isSelect.value == "1") {
          tableHeight.value = window.innerHeight - 450;
        } else {
          tableHeight.value = window.innerHeight - 470;
        }
      }
    };

    // 重置筛选条件
    const resetFilter = () => {
      vinNo.value = "";
      vinNoArr.value.length = 0;
      newVinNo.value = "";
      newVinNoArr.value.length = 0;
      typeClass.value = "";
      approlResult.value = null;
      approlStatus.value = null;
      submitStartDate.value = "";
      submitEndDate.value = "";
      transformStartDate.value = "";
      transformEndDate.value = "";
      certificationStartDate.value = "";
      certificationEndDate.value = "";
      newStartDate.value = "";
      newEndDate.value = "";
      newInvoiceDateFrom.value = "";
      newInvoiceDateTo.value = "";
      newInvoiceStartDate.value = "";
      newInvoiceEndDate.value = "";
      registrationStartDate.value = "";
      registrationEndDate.value = "";
      invoiceStartDate.value = "";
      invoiceEndDate.value = "";
      regionVal.value = "";
      dealerCode.value = "";
      dealerName.value = "";
      headerParams.value.quarter = null;
      headerParams.value.unSubmit = 102;
      isAppeal.value = "";
    };
    // 上传弹窗是否可见
    const isUploadVisible = ref<boolean>(false);
    const upLoad = () => {
      isUploadVisible.value = true;
    };
    // 核查操作框 行间按钮
    const checkModelShow = (param: any) => {
      isCheckVisible.value = true;
      checkList.value = param;
      Object.assign(checkList.value, { types: isSelect.value, operationType: isSelect.value === '0' ? 1 : 2 });
    };
    // 表格选择操作
    const stateSelectedRowKeys = reactive<[]>([]);
    const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
      stateSelectedRowKeys.length = 0;
      stateSelectedRowKeys.push(...selectedRows);
    };
    // 批量下载文件
    const batchDownDoc = () => {
      if (isSelect.value == "0") {
        const tempObj = {
          checkResult: approlResult.value,
          checkStatus: approlStatus.value
            ? approlStatus.value
            : headCheckState.value,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: dealerCode.value,
          dealerId: "",
          dealerName: dealerName.value,
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          model: typeClass.value,
          quarter: null,
          region: regionVal.value,
          size: pagination.pageSize,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          vinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          idList: stateSelectedRowKeys.map((item: any) => item.id),
          isAppeal: isAppeal.value,
        };
        s3Download({
          url: "/rvapi/ucMarketInfo/downloadDocZip",
          method: "POST",
          data: tempObj,
          headers: {
            "code-handler": "ignore",
          },
        });
      } else {
        const tempObj1 = {
          checkResult: approlResult.value,
          checkStatus: approlStatus.value
            ? approlStatus.value
            : headCheckState.value,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: dealerCode.value,
          dealerId: "",
          dealerName: dealerName.value,
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          newInvoiceDateFrom: newInvoiceDateFrom.value,
          newInvoiceDateTo: newInvoiceDateTo.value,
          model: typeClass.value,
          quarter: null,
          region: regionVal.value,
          size: pagination.pageSize,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          usedCarVinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          vehicleVinNos: newVinNoArr.value.length
            ? newVinNoArr.value
            : [newVinNo.value],
          idList: stateSelectedRowKeys.map((item: any) => item.id),
          isAppeal: isAppeal.value,
        };
        s3Download({
          url: "/rvapi/ucReplacementInfo/downloadDocZip",
          method: "POST",
          data: tempObj1,
          headers: {
            "code-handler": "ignore",
          },
        });
      }
    };
    // 导出结果  cpo+置换
    const exportResultBtn = (params: any) => {
      if (isSelect.value == "0") {
        const fileName = `XS_(${moment(new Date()).format(
          "YYYYMMDDhhmmss"
        )}).xlsx`;

        downloadFile({
          url: "/rvapi/ucMarketInfo/export?type=2",
          method: "post",
          params: exportParams.value,
          fileName: fileName,
        });
      } else {
        const fileName = `ZH_(${moment(new Date()).format(
          "YYYYMMDDhhmmss"
        )}).xlsx`;
        downloadFile({
          url: "/rvapi/ucReplacementInfo/export?type=2",
          method: "post",
          params: exportParams.value,
          fileName: fileName,
        });
      }
    };

    // CPO销售页签表头

    // 上传核查结果
    const checkUploadModel = () => {
      isCheckUploadVisible.value = true;
    };
    // 初始化页头统计区域
    const initHeaderCar = () => {
      if (isSelect.value == "1") {
        getOverViewTrande({}).then((res: any) => {
          Object.assign(salesCard.value, res);
        });
      } else {
        getOverView({}).then((res: any) => {
          Object.assign(salesCard.value, res);
        });
      }
    };
    // 初始化表格信息
    const getTableData2 = (params: any) => {
      initHeaderCar(); //初始化页头卡片信息
      // cpo销售
      if (isSelect.value == "0") {
        // cpo销售清单信息
        const tempObj = {
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          checkStatus: headerParams.value.unSubmit,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: "",
          dealerId: "",
          size: pagination.pageSize,
          vinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          quarter: null,
          isAppeal: isAppeal.value,
        };

        Object.assign(tableCurrentInfo, tempObj);
        exportParams.value = tempObj;
        getpageList(tempObj).then((res: any) => {
          tableData.length = 0;
          tableData.push(...res.content);
          pagination.pageSize = res.size;
          pagination.total = res.totalElements;
        });
      } else {
        const tempObj1 = {
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          newInvoiceDateFrom: newInvoiceDateFrom.value,
          newInvoiceDateTo: newInvoiceDateTo.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          usedCarVinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          vehicleVinNos: newVinNoArr.value.length
            ? newVinNoArr.value
            : [newVinNo.value],
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          checkStatus: approlStatus.value
            ? approlStatus.value
            : headerParams.value.unSubmit,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerCode: "",
          dealerId: "",
          size: pagination.pageSize,
          quarter: null,
          isAppeal: isAppeal.value,
        };

        Object.assign(tableCurrentInfo, tempObj1);
        exportParams.value = tempObj1;
        // 置换清单信息
        getpageListTrande(tempObj1).then((res: any) => {
          tableDataTrande.length = 0;
          tableDataTrande.push(...res.content);
          pagination.pageSize = res.size;
          pagination.currentPage = res.totalPages >= 1 ? res.totalPages - 1 : 0;
          pagination.total = res.totalElements;
        });
      }
    };
    const getTableData = (params: any) => {
      initHeaderCar(); //初始化页头卡片信息
      // cpo销售
      if (isSelect.value == "0") {
        // cpo销售清单信息
        const tempObj = {
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          vinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          certificationDateFrom: certificationStartDate.value,
          certificationDateTo: certificationEndDate.value,
          firstRegistDateFrom: registrationStartDate.value,
          firstRegistDateTo: registrationEndDate.value,
          startRepairDateFrom: newStartDate.value,
          startRepairDateTo: newEndDate.value,
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          region: regionVal.value,
          checkStatus: approlStatus.value
            ? approlStatus.value
            : headCheckState.value,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerName: dealerName.value,
          dealerCode: dealerCode.value,
          dealerId: "",
          size: pagination.pageSize,
          quarter: null,
          model: typeClass.value,
          isAppeal: isAppeal.value,
        };

        Object.assign(tableCurrentInfo, tempObj);
        exportParams.value = tempObj;
        getpageList(tempObj).then((res: any) => {
          tableData.length = 0;
          tableData.push(...res.content);
          pagination.pageSize = res.size;
          pagination.total = res.totalElements;
        });
      } else {
        const tempObj1 = {
          newInvoiceDateFrom: newInvoiceDateFrom.value,
          newInvoiceDateTo: newInvoiceDateTo.value,
          submitDateFrom: submitStartDate.value,
          submitDateTo: submitEndDate.value,
          usedCarVinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
          vehicleVinNos: newVinNoArr.value.length
            ? newVinNoArr.value
            : [newVinNo.value],
          model: typeClass.value,
          firstRegistDateFrom: registrationStartDate.value,
          firstRegistDateTo: registrationEndDate.value,
          invoiceDateFrom: invoiceStartDate.value,
          invoiceDateTo: invoiceEndDate.value,
          transferDateFrom: transformStartDate.value,
          transferDateTo: transformEndDate.value,
          region: regionVal.value,
          dealerName: dealerName.value,
          dealerCode: dealerCode.value,
          vehicleLicense: carNo.value,
          checkResult: approlResult.value,
          checkStatus: approlStatus.value
            ? approlStatus.value
            : headCheckState.value,
          current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
          dealerId: "",
          size: pagination.pageSize,
          quarter: null,
          isAppeal: isAppeal.value,
        };

        Object.assign(tableCurrentInfo, tempObj1);
        exportParams.value = tempObj1;
        // 置换清单信息
        getpageListTrande(tempObj1).then((res: any) => {
          tableDataTrande.length = 0;
          tableDataTrande.push(...res.content);
          pagination.pageSize = res.size;
          pagination.total = res.totalElements;
        });
      }
    };

    const batchSubmitInterface = computed(() => {
      return isSelect.value == "0" ? batchSubmit : batchSubmitTrande;
    });

    const submitSuccess = (res: any) => {
      getTableData({});
      message.success(`${res} 条记录提交成功！`);
    };

    // 批量提交请求事件 cpo+置换
    const batchSub = (ok: () => void, isCheckSubmit = false) => {
      Modal.confirm({
        title: "提示",
        okText: !isCheckSubmit ? "Confirm" : "OK",
        content: !isCheckSubmit
          ? "Do you confirm submit all search result?"
          : "Submit for Batch Approve?",
        onOk() {
          ok();
        },
        // onCancel() {
        //   message.success(subArr.length + "条记录取消提交成功！");
        // },
      });
    };

    const handleOk = (params: any) => {
      batchSubmitInterface.value(params).then((res: any) => {
        submitSuccess(res);
      });
    };

    const batchSubmitHandler = () => {
      let params = {};
      if (stateSelectedRowKeys.length === 0) {
        params = Object.assign({ idList: [], type: 2 }, tableCurrentInfo);
      } else {
        params = {
          idList: stateSelectedRowKeys.map((item: any) => item.id),
          type: 2,
        };
      }
      batchSub(() => handleOk(params));
    };

    // 核查提交 行间按钮
    const checkSubmit = (id: any) => {
      const params = { idList: [id], type: 2 };
      batchSub(() => handleOk(params), true);
    };

    getTableData({});
    // 头部卡片蓝色数字点击数据穿透查询
    const showInfo = (type: number) => {
      if (type == 1) {
        // 如果是点击的总计数字发起查询，全部清空，除年份和季度之外
        headerParams.value.unSubmit = 102;
        headCheckState.value = "102";
        resetFilter(); //全部清空
      } else if (type == 2) {
        // 未提交
        resetFilter(); //全部清空
        headerParams.value.unSubmit = 101;
        headCheckState.value = "101";
      } else {
        // type为3表示申诉审核中
        resetFilter(); //全部清空
        headerParams.value.unSubmit = 9;
        headCheckState.value = "9";
      }
      isSearch.value = true;
      pagination.currentPage = 1;
      getTableData2({
        unsubmittedCount: headerParams.value.unSubmit,
      });
      resetFilter(); //全部清空
    };
    // 切换页签
    const changeTab = (param: string) => {
      // 批量下载按钮不可用
      isSearch.value = false;
      pagination.currentPage = 1;
      pagination.pageSize = 10;
      isSelect.value = param;
      isOpen.value = false;
      // tableHeight.value = 280;
      if (param == "1") {
        tableHeight.value = window.innerHeight - 450;
      } else {
        tableHeight.value = window.innerHeight - 470;
      }
      resetFilter(); //重置筛选条件
      getTableData(tableCurrentInfo); //刷新表格
    };

    const resetSelectedRows = () => {
      stateSelectedRowKeys.length = 0;
    };
    // 筛选条件查询事件
    const searchTable = () => {
      resetSelectedRows();
      pagination.currentPage = 1;
      const tempObj = {
        checkResult: approlResult.value,
        checkStatus: approlStatus.value
          ? approlStatus.value
          : headerParams.value.unSubmit,
        submitDateFrom: submitStartDate.value,
        submitDateTo: submitEndDate.value,
        transformDateFrom: transformStartDate.value,
        transformDateTo: transformEndDate.value,
        region: regionVal.value,
        vinNo: vinNo.value,
        usedCarVinNo: typeClass.value,
        vehicleLicense: carNo.value,
        size: pagination.pageSize,
        current: pagination.currentPage >= 1 ? pagination.currentPage - 1 : 0,
        usedCarVinNos: vinNoArr.value.length ? vinNoArr.value : [vinNo.value],
      };
      // 批量下载按钮可用
      isSearch.value = true;
      getTableData(tempObj);
      // 如果是点击的总计数字发起查询，全部清空，除年份和季度之外
      // resetFilter(); //全部清空
      isOpen.value = false;
      refreshTableHeight();
    };

    //更改分页页签
    const pageChange = (page: number) => {
      pagination.currentPage = page;
      stateSelectedRowKeys.length = 0;
      getTableData(tableCurrentInfo);
    };
    //改变每页大小
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      stateSelectedRowKeys.length = 0;
      getTableData(tableCurrentInfo);
    };

    //获取region数组
    const getRegion = () => {
      getRegionApi({ bu: "CPO" }).then((res) => {
        regionArray.value = res;
      });
    };
    getRegion();

    const checkResultStr = (record: {
      checkResult: number;
      siccoCheckResult: number;
    }) => {
      if (record.checkResult === 0) {
        return "Unchecked";
      }
      if (record.checkResult === 1) {
        return "Approved";
      }
      if (record.checkResult === 2) {
        return "Rejected";
      }
      return "";
    };

    return {
      regionArray,
      tableWidth,
      tableHeight,
      refreshTableHeight,
      pagination,
      isSelect,
      changeTab,
      getTableData,
      salesCard,
      showInfo,
      sizeChange,
      pageChange,
      size,
      isOpenPanle,
      isCheckUploadVisible,
      checkClose,
      isCheckVisible,
      checkUploadClose,
      checkUploadModel,
      checkModelShow,
      checkSubmit,
      checkList,
      isOpen,
      columns,
      columnsCPO,
      tableData,
      tableDataTrande,
      upLoad,
      isUploadVisible,
      batchDownDoc,
      exportResultBtn,
      typeClass,
      transformStartDate,
      transformEndDate,
      vinNo,
      vinNoArr,
      approlResult,
      regionVal,
      dealerCode,
      dealerName,
      isAppeal,
      batchQueryEvent,
      isInputVisible,
      dateChangeTranstorm,
      certificationStartDate,
      certificationEndDate,
      dateChangeCertification,
      newStartDate,
      newEndDate,
      dateChangeNew,
      newInvoiceStartDate,
      newInvoiceEndDate,
      dateChangenewInvoice,
      registrationStartDate,
      registrationEndDate,
      dateChangeRegistration,
      invoiceStartDate,
      invoiceEndDate,
      approlStatus,
      dateChangeInvoice,
      dateChangeSubmit,
      submitStartDate,
      submitEndDate,
      resetFilter,
      searchTable,
      tableCurrentInfo,
      approvalResultArray,
      approvalStatusArray,
      batchSubmitHandler,
      onSelectChange,
      stateSelectedRowKeys,
      carNo,
      isSearch,
      isInputVisibleNew,
      newVinNo,
      newVinNoArr,
      dateChangeInvoiceNew,
      newInvoiceDateFrom,
      newInvoiceDateTo,
      checkResultStr,
    };
  },
});
